.header{
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #ffff;
    z-index: 5;
}
.disabled-link {
    pointer-events: none;
  }
.trialLogo{
    width: 70px;
    padding-top: 10px;
}
.trialLogoEN{
    width: 100px;
    padding-top: 10px;
}
.noticeIcon{
    color: orange;
    font-size: 18px;
    margin-left: 1px;
    margin-bottom: 2px;
}