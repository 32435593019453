.sidebarArea {
    flex-shrink: 0;
    width: $sidebarWidth;
    background-color: $sidebarColor;
    .nav-link {
      color: rgba(255, 255, 255, 0.5);
    }
    .sidenav-bg:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: $border-radius;
      .nav-link {
        color: white;
      }
    }
    .sidenav-bg .active {
      background-color: $primary;
      border-radius: $border-radius;
      color: white;
    }
  }
  
  @include media-breakpoint-down(lg) {
    .sidebarArea {
      position: fixed;
      height: 100%;
      overflow: auto;
      top: 0;
      z-index: 1;
      margin-left: -$sidebarWidth;
      transition: 0.2s ease-in;
      &.showSidebar {
        margin-left: 0px;
      }
    }
  }
  